export default {
    capabilities: "Opportunities",
    how_it_works: "How it works",
    tariffs: "Tariffs",
    login_uppercase: "LOGIN",
    first_section_title: "Effective land analysis",
    first_section_description: "Convenient retrieval of information from state registers and open data portals",
    try_for_free: "Try",
    what_can_the_cadastre_ua_system: "What can the Cadastre UA system",
    it_is_convenient_to_search_for_information: "Easy to search for information",
    receive_store_and_analyze_data: "Receive, store and analyze data",

    how_it_works_1_description: "Register and be able to manage data in your own account.",
    how_it_works_2_description: "Create a list of cadastral numbers for which you need information.",
    how_it_works_3_description: "UA Cadastre Service will find all available information in the State Registers and among open data.",
    how_it_works_4_description: "Use the analytics provided by the service, or upload the data in a convenient format.",
    how_it_works_5_description: "Take information with you through a single mobile app.",

    information_from_registers_and_open_data: "information from open data registers and portals",
    number_of_land_plots_in_one_request: "Number of plots in one request",
    up_to_1000_pcs: "up to 1000 pcs",
    up_to_10000_pcs: "up to 10 000 pcs",
    more_than_10000_pcs: "more than 10 000 pcs",
    more_than_10000_pcs_2: "10,000 + pcs",
    price_tag: "{0} UAH / pc",
    DZK: "DZK",
    DRRP: "DRRP",
    NGO: "NGO",
    plot_vector: "Plot vector",
    complete_analytics: "Complete Analytics",
    to_start_work_you_need_a_deposit_of_2000_uah: "A deposit of UAH 2,000 is required to get started",

    what_system_can_do__it_is_convenient_to_search_for_information__information_1: "Direct on the map: work with both a single site and a group",
    what_system_can_do__it_is_convenient_to_search_for_information__information_2: "Download data from file",
    what_system_can_do__it_is_convenient_to_search_for_information__information_3: "Use search string for various parameters: cadastral number, COATUU code, geographical coordinates, geographical names",

    what_system_can_do__receive_store_and_analyze_data__information_1: "Getting extracts from registers. Currently available data from the following services: PKKU, NGO, DRRP, DZK",
    what_system_can_do__receive_store_and_analyze_data__information_2: "Upload data in different formats: tables (xlsx), coordinates (kml, geojson), documents (pdf)",
    what_system_can_do__receive_store_and_analyze_data__information_3: "Save History and Search Results",
    what_system_can_do__receive_store_and_analyze_data__information_4: "Convenient data filtering with results displayed on a map",
    what_system_can_do__receive_store_and_analyze_data__information_5: "Ability to analyze the obtained data in different planes",

    information_is_always_there_even_in_the_absence_of_the_internet: "Information is always there, even when there is no internet",
    footer_copyright: "© {0} UA Cadastre. All rights reserved.",
    footer_public_offer: "Public offer.",
    information_from_registers_and_open_data_capitalize: "Information from registers and open data",
    price: "Price",
    DEMO: "DEMO",
    signIn: "Sign In",
    cadastr: "KADASTR UA",
    contact_us: "Contact us",
}
